.error {
    border: 1px solid red;
  }
  .error-message {
    color: red;
    font-size: 0.8em;
    margin-left: 10px;
  }
  .error-modal {
    background-color: #ffc0cb; /* Light red background */
    color: red;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
  }