.main-container {
  max-width: 1200px; /* Or whatever maximum width you prefer */
  margin: 0 auto; /* This will center your content and provide equal margins on both sides */
  padding: 0 20px; /* Optional: if you want some padding inside the container */
}

/* You can also apply this only to desktop view using a media query */
@media (min-width: 769px) {
  .main-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
  }
}

header {
  background-color: #282c34;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  height: 70%;
}

.signUpButton {
  background-color: green;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.signUpButton:hover {
  background-color: #282c34;
  color: white;
  cursor: pointer;
}

.signUpButton:disabled {
  background-color: grey;
  cursor: not-allowed;
}